import authApi from "../services/auth";
import invoiceApi from "../services/billing/invoices";
import paymentApi from "../services/billing/payment";
import productCollectionApi from "../services/billing/productCollections";
import productApi from "../services/billing/products";
import subscriptionPlanApi from "../services/billing/subscriptionPlans";
import subscriptionApi from "../services/billing/subscriptions";
import transactionApi from "../services/billing/transactions";
import brokerageApi from "../services/brokerage";
import categoryApi from "../services/category";
import categoryChatApi from "../services/category-chat";
import categoryGroupsApi from "../services/category-group";
import categorySettingsApi from "../services/category-settings";
import categoryTypesApi from "../services/category-type";
import cleanupApi from "../services/clean-up";
import dataEntryLogsApi from "../services/data-entry-logs";
import dutiesApi from "../services/duties";
import dutyEmailsApi from "../services/duty-emails";
import dutyScheduleApi from "../services/duty-schedule";
import dutySchedulingApi from "../services/duty-scheduling";
import errorLogsApi from "../services/error-logs";
import eventTypeApi from "../services/event-type";
import featureSettingsApi from "../services/feature-settings";
import fileApi from "../services/file";
import groupApi from "../services/group";
import groupRoleApi from "../services/group-role";
import invitaionLogsApi from "../services/invitaion-logs";
import invitationApi from "../services/invitation";
import landingPageApi from "../services/landingPage";
import listingTourApi from "../services/listing-tours";
import listingApi from "../services/listings";
import listingsOnToursApi from "../services/listings-on-the-tours";
import mapsApi from "../services/maps-api";
import memberApi from "../services/member";
import messagesApi from "../services/messages";
import migrationApi from "../services/migration";
import notificationsApi from "../services/notifications";
import passwordApi from "../services/password";
import postApi from "../services/post";
import redirectApi from "../services/redirect";
import reosadminApi from "../services/reosadmin";
import roleApi from "../services/role";
import rolePermissionEnumsApi from "../services/role-permission-enum";
import searchApi from "../services/search";
import teamApi from "../services/team";
import teamRoleApi from "../services/team-role";
import ticketApi from "../services/ticket";
import tierApi from "../services/tier";
import tierPermissionApi from "../services/tier-permission";
import userApi from "../services/user";
import userRoleNameEnumsApi from "../services/user-role-name-enum";
import widgetPostsApi from "../services/widget-posts";
import chatbotApi from "../services/chatbot";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";


const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [featureSettingsApi.reducerPath]: featureSettingsApi.reducer,
    [redirectApi.reducerPath]: redirectApi.reducer,
    [cleanupApi.reducerPath]: cleanupApi.reducer,
    [userRoleNameEnumsApi.reducerPath]: userRoleNameEnumsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [passwordApi.reducerPath]: passwordApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [teamRoleApi.reducerPath]: teamRoleApi.reducer,
    [invitationApi.reducerPath]: invitationApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [widgetPostsApi.reducerPath]: widgetPostsApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [errorLogsApi.reducerPath]: errorLogsApi.reducer,
    [invitaionLogsApi.reducerPath]: invitaionLogsApi.reducer,
    [dataEntryLogsApi.reducerPath]: dataEntryLogsApi.reducer,
    [brokerageApi.reducerPath]: brokerageApi.reducer,
    [categoryTypesApi.reducerPath]: categoryTypesApi.reducer,
    [categoryGroupsApi.reducerPath]: categoryGroupsApi.reducer,
    [categoryChatApi.reducerPath]: categoryChatApi.reducer,
    [categorySettingsApi.reducerPath]: categorySettingsApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [tierApi.reducerPath]: tierApi.reducer,
    [tierPermissionApi.reducerPath]: tierPermissionApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [reosadminApi.reducerPath]: reosadminApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [groupRoleApi.reducerPath]: groupRoleApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [eventTypeApi.reducerPath]: eventTypeApi.reducer,
    [migrationApi.reducerPath]: migrationApi.reducer,
    [dutySchedulingApi.reducerPath]: dutySchedulingApi.reducer,
    [dutiesApi.reducerPath]: dutiesApi.reducer,
    [dutyScheduleApi.reducerPath]: dutyScheduleApi.reducer,
    [dutyEmailsApi.reducerPath]: dutyEmailsApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [productCollectionApi.reducerPath]: productCollectionApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [subscriptionPlanApi.reducerPath]: subscriptionPlanApi.reducer,
    [listingsOnToursApi.reducerPath]: listingsOnToursApi.reducer,
    [listingTourApi.reducerPath]: listingTourApi.reducer,
    [listingApi.reducerPath]: listingApi.reducer,
    [mapsApi.reducerPath]: mapsApi.reducer,
    [rolePermissionEnumsApi.reducerPath]: rolePermissionEnumsApi.reducer,
    [landingPageApi.reducerPath]: landingPageApi.reducer,
    [chatbotApi.reducerPath]: chatbotApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(featureSettingsApi.middleware)
      .concat(redirectApi.middleware)
      .concat(cleanupApi.middleware)
      .concat(userRoleNameEnumsApi.middleware)
      .concat(userApi.middleware)
      .concat(passwordApi.middleware)
      .concat(teamApi.middleware)
      .concat(teamRoleApi.middleware)
      .concat(invitationApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(messagesApi.middleware)
      .concat(memberApi.middleware)
      .concat(postApi.middleware)
      .concat(widgetPostsApi.middleware)
      .concat(errorLogsApi.middleware)
      .concat(invitaionLogsApi.middleware)
      .concat(dataEntryLogsApi.middleware)
      .concat(categoryApi.middleware)
      .concat(brokerageApi.middleware)
      .concat(categoryTypesApi.middleware)
      .concat(categoryGroupsApi.middleware)
      .concat(categoryChatApi.middleware)
      .concat(categorySettingsApi.middleware)
      .concat(ticketApi.middleware)
      .concat(tierApi.middleware)
      .concat(tierPermissionApi.middleware)
      .concat(roleApi.middleware)
      .concat(searchApi.middleware)
      .concat(reosadminApi.middleware)
      .concat(groupApi.middleware)
      .concat(groupRoleApi.middleware)
      .concat(eventTypeApi.middleware)
      .concat(migrationApi.middleware)
      .concat(fileApi.middleware)
      .concat(dutySchedulingApi.middleware)
      .concat(dutiesApi.middleware)
      .concat(dutyScheduleApi.middleware)
      .concat(dutyEmailsApi.middleware)
      .concat(transactionApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(productApi.middleware)
      .concat(productCollectionApi.middleware)
      .concat(paymentApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(subscriptionPlanApi.middleware)
      .concat(listingsOnToursApi.middleware)
      .concat(listingTourApi.middleware)
      .concat(listingApi.middleware)
      .concat(mapsApi.middleware)
      .concat(rolePermissionEnumsApi.middleware)
      .concat(landingPageApi.middleware)
      .concat(chatbotApi.middleware),
});

setupListeners(store.dispatch);

export default store;