import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const chatbotApi = createApi({
  reducerPath: "chatbot",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/chatbot/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: ({ userId, chatId, message, context }) => ({
        url: "/chat",
        method: "POST",
        body: { userId, chatId, message, context },
      }),
    }),
    getChatHistory: builder.query({
      query: (userId) => `/history/${userId}`,
    }),
    startNewChat: builder.mutation({
      query: ({ userId, initialMessage }) => ({
        url: "/start",
        method: "POST",
        body: { userId, initialMessage },
      }),
    }),
    updateChatTitle: builder.mutation({
      query: ({ chatId, newTitle }) => ({
        url: `/update-title/${chatId}`,
        method: "PUT",
        body: { newTitle },
      }),
    }),
    deleteChat: builder.mutation({
      query: (chatId) => ({
        url: `/delete/${chatId}`,
        method: "DELETE",
      }),
    }),
    getRecentChats: builder.query({
      query: (userId) => `/recent/${userId}`,
    }),
    clearChatHistory: builder.mutation({
      query: (userId) => ({
        url: `/clear/${userId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useSendMessageMutation,
  useGetChatHistoryQuery,
  useStartNewChatMutation,
  useUpdateChatTitleMutation,
  useDeleteChatMutation,
  useGetRecentChatsQuery,
  useClearChatHistoryMutation,
} = chatbotApi;

export default chatbotApi;
export { chatbotApi };