import { AuthContext } from "../../context/AuthContext";
import {
  useSendMessageMutation,
  useGetChatHistoryQuery,
  useStartNewChatMutation,
  useUpdateChatTitleMutation,
  useDeleteChatMutation,
  useClearChatHistoryMutation,
} from "../../services/chatbot";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useState, useContext, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";

const AgentAI = () => {
  const { userId } = useContext(AuthContext);
  const [selectedChatId, setSelectedChatId] = useState(
    localStorage.getItem("selectedChatId") || null
  );
  const location = useLocation();
  const WelcomeMessage = `Welcome to AgentCoachAI! 
  How can I help you today? 
  I have been trained to assist you with a variety of topics related to Real Estate. 
  Enter your query below to get started.`;
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [chatTitle, setChatTitle] = useState(
    localStorage.getItem("chatTitle") || "New Chat"
  );
  const [editingTitle, setEditingTitle] = useState(false);
  const [hasStartedChat, setHasStartedChat] = useState(false); // Prevent duplicate calls

  const [sendMessage, { isLoading }] = useSendMessageMutation();
  const [startNewChat] = useStartNewChatMutation();
  const [updateChatTitle] = useUpdateChatTitleMutation();
  const [deleteChat] = useDeleteChatMutation();
  const [clearChatHistory] = useClearChatHistoryMutation();
  const {
    data: chatHistory,
    isLoading: historyLoading,
    refetch,
  } = useGetChatHistoryQuery(userId);

  const chatHistoryRef = useRef(null);
  // State for confirmation dialogs
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openClearConfirm, setOpenClearConfirm] = useState(false);

  useEffect(() => {
    if (chatHistoryRef.current) {
      // Scroll to top when switching chats
      const lastMessageElement = chatHistoryRef.current.lastElementChild;
      if (lastMessageElement) {
        lastMessageElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedChatId]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      setTimeout(() => {
        const messageList = chatHistoryRef.current.querySelectorAll("li"); // Get all messages
        if (messageList.length > 0) {
          const lastMessageElement = messageList[messageList.length - 1]; // Select the last message

          const lastMessage = messages[messages.length - 1];

          if (lastMessage?.role === "user") {
            lastMessageElement.scrollIntoView({
              behavior: "smooth",
              block: "start", // Scroll to the exact top
            });
          } else {
            lastMessageElement.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });

            // Slightly adjust scroll position to show some context above the assistant's reply
            chatHistoryRef.current.scrollTop += 100; // Adjust this value as needed
          }
        }
      }, 100);
    }
  }, [messages]);

  let tick = 0;
  // Extract query from URL and start a new chat if needed
  useEffect(() => {
    if (!userId || tick > 0) return;

    const params = new URLSearchParams(location.search);
    const query = params.get("query");

    if (query && query.trim().length > 0) {
      console.log("Query detected, starting chat with:", query);

      setHasStartedChat(true); // Ensure it runs only once
      handleNewChatWithQuery(query);

      // Clear the query parameter from the URL
      const newUrl = new URL(window.location);
      newUrl.searchParams.delete("query");
      window.history.replaceState({}, document.title, newUrl.toString());

      console.log("tick", tick++);
    }
  }, [location.search, userId]);

  // Auto-update chat history when a new chat is created or a message is sent
  useEffect(() => {
    refetch();
  }, [selectedChatId, messages]);

  // Restore chat state on reload
  useEffect(() => {
    if (selectedChatId) {
      const storedMessages = localStorage.getItem(`chat_${selectedChatId}`);
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages));
      }
    }
  }, [selectedChatId]);

  // Store selected chat ID and title in localStorage
  useEffect(() => {
    if (selectedChatId) {
      localStorage.setItem("selectedChatId", selectedChatId);
    }
    localStorage.setItem("chatTitle", chatTitle);
  }, [selectedChatId, chatTitle]);

  // Load selected chat history
  const loadChat = (chatId) => {
    const chat = chatHistory?.chats.find((c) => c._id === chatId);
    if (!chat) return;

    setMessages(chat.messages || []);
    setSelectedChatId(chatId);
    setChatTitle(chat.title || "New Chat");

    // Store chat messages in localStorage
    localStorage.setItem(`chat_${chatId}`, JSON.stringify(chat.messages));
  };

  // Start a new conversation with a query
  const handleNewChatWithQuery = async (query) => {
    if (!query?.trim()) return; // Prevent empty queries

    setMessages([]);

    try {
      clearAllLocalStorage();

      const res = await startNewChat({
        userId,
        initialMessage: query,
      }).unwrap();

      setSelectedChatId(res.chatId);

      // Set initial user message without triggering `useEffect`
      const userMessage = {
        role: "user",
        content: query,
        timestamp: new Date(),
      };
      setMessages([userMessage]);

      // Fetch assistant's response
      const aiResponse = await sendMessage({
        userId,
        chatId: res.chatId,
        message: query,
        context: [],
      }).unwrap();

      setChatTitle(aiResponse.title);

      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: aiResponse.response,
          timestamp: new Date(),
        },
      ]);

      // Store chat in local storage (ensure correct message structure)
      localStorage.setItem(
        `chat_${res.chatId}`,
        JSON.stringify([
          userMessage,
          {
            role: "assistant",
            content: aiResponse.response,
            timestamp: new Date(),
          },
        ])
      );
      refetch(); // Ensures chat history updates
    } catch (error) {
      console.error("Error starting new chat with query:", error);
    }
  };

  const clearAllLocalStorage = (all = "") => {
    localStorage.removeItem("selectedChatId");
    localStorage.removeItem("chatTitle");

    if (all === "all") {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("chat_")) {
          localStorage.removeItem(key);
        }
      });
    } else {
      localStorage.removeItem(`chat_${selectedChatId}`);
    }
  };

  // Start a new conversation
  const handleNewChat = async () => {
    try {
      clearAllLocalStorage();
      const res = await startNewChat({ userId, initialMessage: "" }).unwrap();
      setSelectedChatId(res.chatId);
      setMessages([]);
      setChatTitle(res.title);
      refetch();
    } catch (error) {
      console.error("Error starting new chat:", error);
    }
  };

  // Handle title edit
  const handleTitleUpdate = async () => {
    if (!chatTitle.trim() || !selectedChatId) return;

    try {
      await updateChatTitle({
        chatId: selectedChatId,
        newTitle: chatTitle,
      }).unwrap();
      setEditingTitle(false);
      refetch();
    } catch (error) {
      console.error("Error updating chat title:", error);
    }
  };

  // Send message handler
  const handleSendMessage = async (messageText = null) => {
    const inputMessage = messageText || message.trim();
    if (!inputMessage) return;

    if (!selectedChatId) await handleNewChat();
    const userMessage = {
      role: "user",
      content: inputMessage,
      timestamp: new Date(),
    };
    setMessages((prev) => [...prev, userMessage]);
    setMessage("");

    try {
      const res = await sendMessage({
        userId,
        chatId: selectedChatId,
        message: inputMessage,
        context: messages,
      }).unwrap();
      setChatTitle(res.title);
      const botMessage = {
        role: "assistant",
        content: res.response,
        timestamp: new Date(),
      };

      setMessages((prev) => {
        const updatedMessages = [...prev, botMessage];

        // Store updated messages in localStorage
        localStorage.setItem(
          `chat_${selectedChatId}`,
          JSON.stringify(updatedMessages)
        );

        return updatedMessages; // Ensure state updates properly
      });

      // // Scroll to the top of the new message
      // if (chatHistoryRef.current) {
      //   const lastMessageElement = chatHistoryRef.current.lastElementChild;
      //   if (lastMessageElement) {
      //     lastMessageElement.scrollIntoView({
      //       behavior: "smooth",
      //       block: "start",
      //     });
      //   }
      // }
    } catch (error) {
      console.error("Error sending message:", error);

      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "⚠️ Error fetching response. Please try again.",
          timestamp: new Date(),
        },
      ]);
    }
  };

  // Get last message timestamp
  const getLastMessageDate = (chat) => {
    if (!chat?.messages || chat.messages.length === 0) return "No Messages";
    const lastMessage = chat.messages[chat.messages.length - 1];
    return new Date(lastMessage.timestamp).toLocaleString();
  };

  // Delete chat
  const handleDeleteChat = async () => {
    if (!selectedChatId) return;

    try {
      await deleteChat(selectedChatId).unwrap();
      clearAllLocalStorage();
      setSelectedChatId(null);
      setMessages([]);
      setChatTitle("New Chat");

      refetch();
    } catch (error) {
      console.error("Error deleting chat:", error);
    } finally {
      setOpenDeleteConfirm(false);
    }
  };

  // Clear chat history
  const handleClearChatHistory = async () => {
    try {
      await clearChatHistory(userId).unwrap();
      clearAllLocalStorage("all");
      setSelectedChatId(null);
      setMessages([]);
      setChatTitle("New Chat");
      //clear all the local storages that are set in this file
      refetch();
    } catch (error) {
      console.error("Error clearing chat history:", error);
    } finally {
      setOpenClearConfirm(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "95vh", // Full screen height
          width: "80vw", // Full screen width
          overflow: "hidden",
          marginTop: 1,
          marginBottom: 1,
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 4fr 3fr auto", // Proportionate column layout
            alignItems: "center",
            backgroundColor: "#1976d2",
            color: "white",
            padding: 2,
            gap: 2, // Adds spacing between columns
          }}
        >
          {/* Chat Title */}
          <Typography variant="h5" sx={{ color: "white" }}>
            AgentCoachAI Chat
          </Typography>
          {/* Editable Chat Title */}
          {selectedChatId && (
            <TextField
              value={chatTitle}
              label="Chat Title (editable)"
              variant="outlined"
              onChange={(e) => setChatTitle(e.target.value)}
              onBlur={handleTitleUpdate}
              onFocus={() => setEditingTitle(true)}
              sx={{
                backgroundColor: "#1976d2",
                borderRadius: 1,
                width: "50%",
                "& .MuiInputBase-input, & .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            />
          )}
          {/* Chat Selection & New Chat Button */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            {historyLoading ? (
              <CircularProgress />
            ) : (
              chatHistory?.chats.length > 0 && (
                <FormControl fullWidth sx={{ color: "white" }}>
                  <InputLabel
                    id="chat-select-label"
                    sx={{
                      color: "white",
                      "&.Mui-focused": { color: "white" },
                    }}
                  >
                    Select a past conversation...
                  </InputLabel>
                  <Select
                    labelId="chat-select-label"
                    label="Select a past conversation..."
                    value={selectedChatId || ""}
                    onChange={(e) => loadChat(e.target.value)}
                    displayEmpty
                    sx={{
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSelect-icon": { color: "white" },
                      "& .MuiInputBase-root": { color: "white" },
                      "& .MuiInputLabel-root": {
                        backgroundColor: "#000",
                        px: 1,
                      },
                    }}
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{ color: "white", backgroundColor: "#1976d2" }}
                    >
                      Select a past conversation...
                    </MenuItem>
                    {chatHistory?.chats.map((chat) => (
                      <MenuItem
                        key={chat._id}
                        value={chat._id}
                        sx={{
                          color: "white",
                          backgroundColor: "#1976d2",
                          "&.Mui-selected": {
                            backgroundColor: "#333 !important", // Ensures selected color is applied
                          },
                          "&:hover": {
                            backgroundColor: "#ccc !important", // Apply hover background
                            color: "black !important", // Change font to black on hover
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#444 !important", // Slightly lighter on hover
                          },
                        }}
                      >
                        {chat.title || "New Chat"} - {getLastMessageDate(chat)}
                        {chat.updatedAt
                          ? new Date(chat.updatedAt).toLocaleString()
                          : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            )}
            {/* New Chat Button */}
            {messages.length > 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNewChat}
              >
                New Chat
              </Button>
            )}
          </Box>
        </Box>

        <Paper
          ref={chatHistoryRef}
          sx={{
            flexGrow: 1, // Expands to take up most space
            overflowY: "auto",
            padding: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {messages.length === 0 && (
            <Typography
              sx={{ marginTop: 20, lineHeight: 1.7, whiteSpace: "pre-line" }} // Allows the welcome message to span multiple lines
              variant="h4"
              align="center"
            >
              {" "}
              {WelcomeMessage}
            </Typography>
          )}

          <List>
            {messages.map((msg, index) => (
              <ListItem key={index} sx={{ alignItems: "flex-start" }}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                      {msg.role === "user" ? "You" : "AgentCoachAI"}
                    </Typography>
                  }
                  secondary={<ReactMarkdown>{msg.content}</ReactMarkdown>}
                  sx={{
                    textAlign: msg.role === "user" ? "right" : "left",
                    backgroundColor:
                      msg.role === "user" ? "#e3f2fd" : "#f1f1f1",
                    borderRadius: 2,
                    padding: "15px",
                    maxWidth: "80%",
                  }}
                />
              </ListItem>
            ))}
          </List>
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            sx={{ mt: 1 }}
          >
            ⚠️ AI Disclaimer: Responses are generated by AI and should be
            independently verified. This is not legal, financial, or
            professional advice.
          </Typography>
        </Paper>
        {/* Chat Input Box */}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            padding: 1,
            backgroundColor: "#fff",
            borderTop: "1px solid #ddd",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Send"}
          </Button>
          {selectedChatId && (
            <IconButton
              onClick={() => setOpenDeleteConfirm(true)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton onClick={() => setOpenClearConfirm(true)} color="primary">
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Delete Chat Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this chat? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteChat} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Clear Chat History Confirmation Dialog */}
      <Dialog
        open={openClearConfirm}
        onClose={() => setOpenClearConfirm(false)}
      >
        <DialogTitle>Confirm Clear All Chats</DialogTitle>
        <DialogContent>
          Are you sure you want to clear all chat history? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenClearConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClearChatHistory} color="error">
            Clear All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AgentAI;
