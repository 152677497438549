import { AuthContext } from "../../context/AuthContext";
import { useSocket } from "../../context/SocketContext";
import { ThemeContext } from "../../context/ThemeContext";
import { TierContext } from "../../context/TierContext";
import { useGetAllChatsQuery, messagesApi } from "../../services/messages";
import { notificationsApi } from "../../services/notifications";
import EditProfilesDrawer from "../account-profiles/EditProfilesDrawer";
import SettingsDrawer from "../account-profiles/settings/SettingsDrawer";
import EnvironmentBanner from "../helpers/EnvironmentBanner";
import { getInitialsFromUserName } from "../helpers/utils";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
import Messaging from "../messages/messaging";
import TicketDialog from "../support/TicketDialog";
import NotificationMenu from "./notification-menu";
import SearchBar from "./search-bar";
import AgentCoachAIQuery from "./agentCoachAIQuery";
import {
  Chat,
  AccountCircle,
  AccountBox,
  Logout,
  LightMode,
  DarkMode,
  ContactSupportOutlined,
  Settings,
} from "@mui/icons-material/";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
  Tooltip,
  Popover,
  Badge,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useContext, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

function Navbar() {
  const {
    userId,
    token,
    handleLogout,
    userName,
    isTokenReady,
    userImage,
    accountType,
    customSystemName,
  } = useContext(AuthContext);
  const { tierImage, tierSubTitle } = useContext(TierContext);

  const { mode, toggleColorMode } = useContext(ThemeContext);
  const { socket } = useSocket();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down("md"));
  // Retrieve variables from local storage
  const {
    data: chats,
    refetch: refetchChats,
    isSuccess,
  } = useGetAllChatsQuery(userId, {
    skip: !isTokenReady || !userId,
  });
  const formattedUserNameToInitials = getInitialsFromUserName(userName);
  const [chatAnchorEl, setChatAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditProfilesDrawerOpen, setIsEditProfilesDrawerOpen] =
    useState(false);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);

  const handleMessagingClick = (event) => {
    setChatAnchorEl(event.currentTarget);
  };

  const handleMessagingClose = () => {
    setChatAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  useEffect(() => {
    if (socket) {
      socket.on("newNotification", () => {
        dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
      });
      socket.on("newChat", () => {
        dispatch(messagesApi.util.invalidateTags(["Messages"]));
      });
      socket.on("newMessage", () => {
        dispatch(messagesApi.util.invalidateTags(["Messages"]));
      });
    }

    return () => {
      if (socket) {
        socket.off("newNotification");
        socket.off("newChat");
        socket.off("newMessage");
      }
    };
  }, [socket, dispatch]);

  const handleMemberClick = () => {
    setProfileAnchorEl(null);
    setIsDrawerOpen(true);
  };
  const handleEditProfilesDrawerOpen = () => {
    setProfileAnchorEl(null);
    setIsEditProfilesDrawerOpen(true);
  };

  const handleSettingsDrawerOpen = () => {
    setProfileAnchorEl(null);
    setIsSettingsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleEditProfilesDrawerClose = () => {
    setIsEditProfilesDrawerOpen(false);
  };

  const handleSettingsDrawerClose = () => {
    setIsSettingsDrawerOpen(false);
  };

  const handleOpenTicketDialog = () => {
    setOpenTicketDialog(true);
    setProfileAnchorEl(null);
  };

  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
  };

  const totalUnread = chats?.reduce((acc, chat) => {
    const unreadMessages = chat.messages.filter(
      (message) => !message.readBy.includes(userId)
    );
    return acc + unreadMessages.length;
  }, 0);

  async function fileExists(url) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok;
    } catch (error) {
      console.error("Error checking file existence:", error);
      return false;
    }
  }
  const InputBase = (props) => (
    <InputBase
      {...props}
      sx={{
        color: "var(--search-bar-text)",
        // Add any other styling you need here
      }}
    />
  );

  return (
    <AppBar className="navbar app-bar">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link
            to={
              accountType === "reosadmin"
                ? "/user-management"
                : accountType === "brokerage"
                ? "/brokerage-ecosystem-management"
                : "/home"
            }
            className="main-logo-link"
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
              lineHeight: 1.1,
              margin: 5,
              fontSize: isSmallDevice
                ? "0.8rem"
                : isMediumDevice
                ? "0.9rem"
                : "1.2rem",
            }}
          >
            {customSystemName &&
              customSystemName !== "undefined" &&
              customSystemName !== "api-reacircle-fe-dev-f953ac8db600" && (
                <>
                  {fileExists(
                    `/imgs/customizations/${customSystemName}.png`
                  ) && (
                    <img
                      src={
                        tierImage ||
                        `/imgs/customizations/${customSystemName}.png`
                      }
                      style={{ height: "60px" }}
                      alt={customSystemName}
                      className="custom-system"
                    />
                  )}
                </>
              )}

            {tierSubTitle || (
              <>
                {`${isSmallDevice ? " " : "REACirc "}`}
                {`${isLargeDevice ? "Community" : ""}`}
              </>
            )}
          </Link>
          <EnvironmentBanner />
        </Typography>
        {token && isTokenReady && <SearchBar />}
        
        {token && isTokenReady && <AgentCoachAIQuery />}

        {token && isTokenReady && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiSvgIcon-root": {
                fontSize: {
                  xs: "1rem",
                  sm: "1.5rem",
                },
              },
            }}
          >
            <NotificationMenu />
          </Box>
        )}
        
        {token && isTokenReady && (
          <Tooltip title="Open Chat">
            <IconButton
              onClick={handleMessagingClick}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: {
                    xs: "1rem", // font size for extra small devices
                    sm: "1.5rem", // font size for small devices and up
                  },
                },
              }}
            >
              <Badge
                badgeContent={totalUnread}
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: {
                      xs: "0.5rem", // font size for extra small devices
                      sm: "0.75rem", // font size for small devices and up
                      md: "1rem", // font size for medium devices and up
                    },
                    height: {
                      xs: "16px", // height for extra small devices
                      sm: "20px", // height for small devices and up
                      md: "24px", // height for medium devices and up
                    },
                    minWidth: {
                      xs: "16px", // min width for extra small devices
                      sm: "20px", // min width for small devices and up
                      md: "24px", // min width for medium devices and up
                    },
                  },
                }}
              >
                <Chat />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <Popover
          open={Boolean(chatAnchorEl)}
          anchorEl={chatAnchorEl}
          onClose={handleMessagingClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ overflow: "hidden" }}
        >
          {token && isTokenReady && (
            <Messaging
              chats={chats}
              refetch={refetchChats}
              isSuccess={isSuccess}
              handleClose={handleMessagingClose}
            />
          )}
        </Popover>
        {!token ? (
          <Box>
            {/* <Button
              component={Link}
              to="/login"
              variant="contained"
              className="navbar btn btn-nav-link"
            >
              Login
            </Button> */}
          </Box>
        ) : (
          <div className="logged-in-block">
            <Tooltip title="Profile Options" placement="bottom">
              <IconButton onClick={handleProfileMenuOpen}>
                <Typography className="menu-text" mr={2}>
                  {isSmallDevice && `${formattedUserNameToInitials}`}
                  {isMediumDevice &&
                    !isSmallDevice &&
                    `${formattedUserNameToInitials} (${accountType.charAt(0)})`}
                  {isLargeDevice && `${userName} (${accountType})`}
                  {!isSmallDevice &&
                    !isMediumDevice &&
                    !isLargeDevice &&
                    `${userName} (${accountType})`}
                </Typography>
                <Avatar alt={userName} src={userImage}>
                  {!userImage && formattedUserNameToInitials}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={profileAnchorEl}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
              className="menu-profile"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem className="menu-item" onClick={handleMemberClick}>
                <Tooltip
                  title="Go to My Member Profile"
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    <AccountCircle />
                    <Typography className="menu-text">Go to Profile</Typography>
                  </div>
                </Tooltip>
              </MenuItem>
              <MenuItem
                onClick={handleEditProfilesDrawerOpen}
                className="menu-item"
              >
                <Tooltip
                  title="Edit My Member and User Account Profiles"
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    <AccountBox />
                    <Typography className="menu-text">Edit Profile</Typography>
                  </div>
                </Tooltip>
              </MenuItem>
              <MenuItem onClick={toggleColorMode} className="menu-item">
                <Tooltip
                  title={`Switch to ${mode === "dark" ? "light" : "dark"} mode`}
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    {mode === "dark" ? <LightMode /> : <DarkMode />}
                    <Typography className="menu-text">
                      {`To ${mode === "dark" ? "Light" : "Dark"} mode`}
                    </Typography>
                  </div>
                </Tooltip>
              </MenuItem>
              <MenuItem
                onClick={handleSettingsDrawerOpen}
                className="menu-item"
              >
                <Tooltip
                  title="Edit My Member and User Account Profiles"
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    <Settings />
                    <Typography className="menu-text">Settings</Typography>
                  </div>
                </Tooltip>
              </MenuItem>
              <MenuItem className="menu-item" onClick={handleOpenTicketDialog}>
                <Tooltip
                  title="Get Support"
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    <ContactSupportOutlined />
                    <Typography className="menu-text">Get Support</Typography>
                  </div>
                </Tooltip>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setProfileAnchorEl(null);
                  handleLogout();
                }}
                className="menu-item"
              >
                <Tooltip
                  title="Logout"
                  className="tooltip-item"
                  placement="left-start"
                >
                  <div className="tooltip-item-div">
                    <Logout />
                    <Typography className="menu-text">Logout </Typography>
                  </div>
                </Tooltip>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      <MemberProfileDrawer
        userId={userId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
      <EditProfilesDrawer
        userId={userId}
        open={isEditProfilesDrawerOpen}
        onClose={handleEditProfilesDrawerClose}
      />
      <SettingsDrawer
        userId={userId}
        open={isSettingsDrawerOpen}
        onClose={handleSettingsDrawerClose}
      />
      <TicketDialog open={openTicketDialog} onClose={handleCloseTicketDialog} />
    </AppBar>
  );
}

export default memo(Navbar);
