import { TextField, Button, Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AgentCoachAIQuery = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (query.trim()) {
      navigate(`/AgentAI?query=${encodeURIComponent(query)}`);
    } else {
      navigate("/AgentAI");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        width: "30%",
        height: "40px",
      }}
    >
      <TextField
        fullWidth
        label="Ask AgentCoachAI..."
        variant="outlined"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleNavigate();
          }
        }}
        size="small" // Makes it more compact
        sx={{ height: "100%" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleNavigate}
        sx={{ textTransform: "none", height: "100%", lineHeight: 1.2 }}
      >
        {query.trim() ? "Ask AgentCoachAI" : "Open AgentCoachAI"}
      </Button>
    </Box>
  );
};

export default AgentCoachAIQuery;
